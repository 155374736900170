<template>
  <div class="custom-project-table">
    <div class="align-items-center card-filter-section d-flex">
      <h3 class="m-0">
        Non Work Projects
      </h3>
      <div class="role-buttons ml-auto d-flex flex-wrap align-items-center">
        <!-- add user button -->
        <table-button
          v-b-toggle.addEditProject
          btn-name="Add Non-work Project"
        />
        <!-- add user button -->
      </div>
      <Help
        v-show="$store.state.app.userManualList"
        code="NP"
      />
    </div>
    <!--filter start-->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="is_active"
              :options="isActiveList"
              :search-box="false"
              :hide-close="true"
              label="Active"
              icon="EditIcon"
            />
          </div>
          <option-drop-down v-model="perPage" />
        </div>
        <div class="d-flex align-items-center">
          <b-input-group
            class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px"
          >
            <b-form-input
              v-model="search"
              placeholder="Search by project Name"
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="search"
                icon="XIcon"
                @click="search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter()"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--filter end-->
    <div class="table">
      <table-loader
        v-if="loading"
        :fields="fields"
      />
      <b-row v-else>
        <b-col
          cols="12"
          class="table m-0"
        >
          <!-- main data tablestart -->
          <b-table
            id="table"
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            show-empty
            class="scrollable-element user-table-items tableDesigns mastertblColor table-responsive non-project-table"
          >
            <template #cell(description)="data">
              <p
                v-b-tooltip.hover.top
                class="project-desc-non d-inline-block word-break"
                :title="data.item.description"
              >
                {{ data.item.description }}
              </p>
            </template>

            <template #cell(active)="data">
              <b-form-checkbox
                v-model="data.item.is_active"
                class="custom-control-success"
                name="check-button"
                size="md"
                switch
                @change="changeStatus(data.index, data.item.is_active)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </template>
            <template #cell(action)="data">
              <span class="action-edit-del">
                <feather-icon
                  icon="EditIcon"
                  class="mr-1 text-dark"
                  size="18"
                  @click="edit(data.item)"
                />
              </span>
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-avatar
                    :text="data.item.name | avatarText"
                    :style="{ 'background-color': '#' + data.item.color }"
                    size="40"
                  />
                </div>
                <div class="media-body">
                  <p
                    v-b-tooltip.hover.top
                    class="custom-project"
                    style="margin-left: 5px"
                    :style="{ color: '#' + data.item.color }"
                    :title="data.item.name | capitalize"
                  >
                    {{ data.item.name }}
                  </p>
                </div>
              </div>

            </template>
            <template #cell(type)="data">
              {{ getProjectType(data.item.type) }}
            </template>
          </b-table>
          <!-- data table complete -->
          <img
            v-if="totalCount === 0"
            src="../../assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
          <!-- main table end -->
        </b-col>

      </b-row>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount>0"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section complete here -->
    </div>
    <!-- add/edit project form start -->
    <non-project-table-form
      @projectList="getProjectData"
      @currentPage="currentPage = 1"
    />
    <!-- add/edit project form complete -->
  </div>
</template>
<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import TableButton from '@/@core/components/buttons/TableButton.vue'
import NonProjectTableForm from '@/@core/components/table-from/nonProjectTableFrom.vue'
import { eventBus } from '@/main'
import VBTooltip from 'bootstrap-vue'

export default {
  name: 'UserTable',
  components: {
    TableButton,
    NonProjectTableForm,
    customDropdown,
  },
  data() {
    return {
      id: 'project',
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      loading: true,
      sortBy: 'name',
      search: null,
      status: [],
      sortDesc: false,
      sortDirection: 'asc',
      is_active: 'active',
      selected: { title: 'Square' },
      option: [
        { title: 'Square' },
        { title: 'Rectangle' },
        { title: 'Rombo' },
        { title: 'Romboid' },
      ],
      fields: [
        {
          key: 'name',
          label: 'Project Name',
          sortable: true,
          thStyle: { width: '15% !important' },
          tdStyle: { width: '15% !important' },
        },
        {
          key: 'description',
          label: 'Project Description',
          thStyle: { width: '75% !important' },
          tdStyle: { width: '75% !important' },
        },
        {
          key: 'active',
          label: 'Active',
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { width: '5%' },
          tdStyle: { width: '5%' },
        },

        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '5%' },
          tdStyle: { width: '5%' },
        },
      ],
      items: [],
      directives: {
        'b-tooltip': VBTooltip,
      },
    }
  },

  watch: {
  /**
   * Watch for changes in currentPage and call getProjectData()
   */
    currentPage: {
      handler(newValue) {
        if (newValue) {
          this.getProjectData()
        }
      },
    },
    /**
   * Watch for changes in search and call getProjectData()
   */
    search() {
      this.getProjectData()
    },
    /**
   * Watch for changes in status and call getProjectData()
   */
    status() {
      this.getProjectData()
    },
    /**
   * Watch for changes in perPage and call getProjectData() or set currentPage to 1
   */
    perPage() {
      if (this.currentPage === 1) {
        this.getProjectData()
      } else {
        this.currentPage = 1
      }
    },
  },

  mounted() {
    this.getProjectData()
  },
  methods: {
    getProjectStatus(value) {
      const status = this.projectStatusList.find(s => s.value === value)
      return status ? status.label : value
    },

    getProjectType(value) {
      const type = this.projectTypeList.find(s => s.value === value)
      return type ? type.label : value
    },

    async getProjectData() {
      try {
        this.loading = true
        const input = {
          page: this.currentPage,
          per_page: this.perPage,
          sort_field: this.sortBy,
          sort_order: this.sortDesc ? 'desc' : 'asc',
          is_active: this.is_active === 'active' ? true : this.is_active === 'inactive' ? false : '',
          search: this.search,
        }
        const response = await this.getHTTPPostResponse('project/non-productive-project', input, false)
        if (response && response.data) {
          const { data } = response
          this.items = data.projects
          this.totalCount = data.count
        }
      } catch (error) {
        console.error('Error fetching project data:', error)
      } finally {
        this.loading = false
      }
    },

    edit(data) {
      eventBus.$emit('edit-project', data.id)
    },

    async confirmStatusChange(index) {
      if (!this.items[index]) return
      const label = this.items[index].is_active ? 'Active' : 'Deactive'
      try {
        const result = await this.$swal({
          title: `Are you sure ${label} Project?`,
          icon: 'info',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: label,
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonAriaLabel: 'Thumbs down',
          customClass: {
            confirmButton: 'btn confirm',
            cancelButton: 'btn cancel ml-1',
          },
          buttonsStyling: false,
        })
        if (result.value) {
          this.updateStatus(this.items[index])
        } else {
          this.items = []
          this.getProjectData()
        }
      } catch (error) {
        console.error('Error confirming status change:', error)
      }
    },

    async updateStatus(data) {
      try {
        const response = await this.getHTTPPostResponse(`project/status/${data.id}?active=${data.is_active ? 1 : 0}`, {}, true)
        if (response && response.status === 200) {
          this.getProjectData()
        }
      } catch (error) {
        console.error('Error updating project status:', error)
      }
    },

    changeStatus(index) {
      const project = this.items[index]
      if (!project) return

      const newStatus = !project.is_active
      const label = newStatus ? 'Active' : 'Deactive'

      this.$swal({
        title: `Are you sure you want to ${label} this project?`,
        icon: 'info',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: label,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.updateStatus(project, newStatus)
        } else {
          // Refresh project data
          this.getProjectData()
        }
      }).catch(error => {
        console.error('Error confirming status change:', error)
      })
    },

    applyFilter() {
      this.getProjectData()
    },

    clearFilter() {
      this.search = null
      this.perPage = 10
      this.is_active = 'active'
      this.getProjectData()
    },
  },

}
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";
@import "../../assets/scss/component-css/masterSettingFilter.scss";
.project-desc-non {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #888888;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.tooltip-inner {
  max-width: 400px;
}
.non-project-table {
  th {
    &:nth-child(2) {
      text-align: start;
    }
  }
}
</style>
