<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="projectObserver">
      <b-sidebar
        id="addEditProject"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <!--  form start -->

        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Non-work Project
        </h3>
        <div
          class="line"
          style="width: 200px"
        />

        <div class="b-sidebar-header">
          <!-- Name input -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Name"
                label-for="projectname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  mode="passive"
                  rules="required|min:3|max:60"
                >
                  <b-form-input
                    id="project name"
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                    name="project name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Project Code"
                label-for="project code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Project code"
                  mode="passive"
                  rules="required|max:5"
                >
                  <b-form-input
                    id="projectcode"
                    v-model="code"
                    type="text"
                    name="projectcode"
                    placeholder="Project Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Description input -->
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  mode="passive"
                  rules="required|max:500"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description"
                    name="description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- project detail complete here -->
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <!-- project color -->
              <b-form-group
                label="Color"
                label-for="project color"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Color"
                  mode="passive"
                  rules="required"
                >
                  <b-input-group class="color-picker-input">
                    <b-form-input
                      id="projectcolor"
                      v-model="projectColor"
                      type="text"
                      name="projectcolor"
                      placeholder="Select any color"
                    />
                    <b-input-group-append is-text>
                      <input
                        v-model="projectColor"
                        type="color"
                        name="projectcolor"
                        class="color-picker"
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Add Project" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData()"
            >
              Clear
            </button>
          </div>
        </template></b-sidebar></validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      code: null,
      id: null,
      name: null,
      description: null,
      projectColor: null,
      btnLoader: false,
    }
  },
  mounted() {
    eventBus.$on('edit-project', id => {
      if (id) {
        this.getProjectDetails(id)
      }
    })
  },
  destroyed() {
    eventBus.$off('edit-project')
  },
  methods: {
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.clearData()
    },
    /**
     * Get project details from id
     * @returns Project Details
     */
    async getProjectDetails(id) {
      const response = await this.getHTTPPostResponse(
        `project/view/${id}`,
        {},
        false,
      )
      if (response && response.data && response.data.project) {
        const data = response.data.project
        this.id = data.id
        this.code = data.code
        this.name = data.name
        this.description = data.description
        this.projectColor = `#${data.color}`
        this.$root.$emit('bv::toggle::collapse', 'addEditProject')
      }
    },
    /**
     * check the form validation
     * @returns if success true then call saveProjectDtl Method
     */
    validationForm() {
      this.$refs.projectObserver.validate().then(success => {
        if (success) {
          this.saveProjectDtl()
        }
      })
    },
    /**
     * Add & Update the project-form details & call project listing in project-table
     * @emits projectList
     * @returns project detail add & update
     */
    async saveProjectDtl() {
      this.btnLoader = true
      const color = this.projectColor.substring(1)
      const input = {
        name: this.name,
        description: this.description,
        code: this.code,
        color,
        work_type: 'non_productive',
      }

      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `project/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'project/create',
          input,
          true,
        )
      }

      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'addEditProject')

        if (!this.id) {
          this.$emit('currentPage', true)
        } else {
          this.$emit('projectList', true)
        }
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },
    /**
     * Clear form details
     */
    clearData() {
      this.name = null
      this.code = null
      this.projectColor = null
      this.description = null
      this.$refs.projectObserver.reset()
    },
  },
}
</script>
