var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"projectObserver"},[_c('b-sidebar',{staticClass:"sidebar-form",attrs:{"id":"addEditProject","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.sidebarToggle},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Add Project")+" ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" Non-work Project ")]),_c('div',{staticClass:"line",staticStyle:{"width":"200px"}}),_c('div',{staticClass:"b-sidebar-header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"projectname"}},[_c('validation-provider',{attrs:{"name":"Name","mode":"passive","rules":"required|min:3|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project name","state":errors.length > 0 ? false : null,"placeholder":"Name","name":"project name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Project Code","label-for":"project code"}},[_c('validation-provider',{attrs:{"name":"Project code","mode":"passive","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"projectcode","type":"text","name":"projectcode","placeholder":"Project Code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","mode":"passive","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Description","name":"description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Color","label-for":"project color"}},[_c('validation-provider',{attrs:{"name":"Color","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"color-picker-input"},[_c('b-form-input',{attrs:{"id":"projectcolor","type":"text","name":"projectcolor","placeholder":"Select any color"},model:{value:(_vm.projectColor),callback:function ($$v) {_vm.projectColor=$$v},expression:"projectColor"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.projectColor),expression:"projectColor"}],staticClass:"color-picker",attrs:{"type":"color","name":"projectcolor"},domProps:{"value":(_vm.projectColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.projectColor=$event.target.value}}})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }